import { Views } from "react-big-calendar";
import {
  CatalogTEObject,
  Registration,
  RegistrationTrack,
  Mapping,
  UnsafeRecord,
  isDefined,
  DedicatedTrack,
  TTEObject,
} from "@timeedit/registration-shared";
import { nbrOfEventColors } from "../../../assets/styles/variables";
import { CalendarEvent } from "../../utils/events";
import {
  EAppProperties,
  EApplicationObjectTypeGroup,
} from "@timeedit/types/lib/enums";
import dayjs from "dayjs";
import { RegistrationRequiredTranslations } from "./types";
import { ConflictNotification } from "../Conflict/index";
import { uniq } from "lodash";

/**
 * Rewritten into typescript from TE Viewer
 * Formats a list of numbers into shortened format
 * example [1,2,3,4,7,8,9] formats into 1-4, 7-9
 */
export class RangeReduce {
  static pairPresent(collection: number[]): string {
    const sortedCollection = collection.sort((a, b) => a - b);
    const pairs = RangeReduce.pair(sortedCollection);
    const result = pairs.reduce<string[]>((result, pair, index) => {
      if (pair.length === 0) {
        return result;
      }

      if (pair.length === 2) {
        if (index === pairs.length - 1) {
          return [...result, pair[0].toString(), "-", pair[1].toString()];
        }
        return [...result, pair[0].toString(), "-", pair[1].toString(), ", "];
      } else {
        const rest = [...result, pair[0].toString()];
        return index === pairs.length - 1 ? [...rest, ""] : [...rest, ", "];
      }
    }, []);
    return result.join("");
  }

  static pair(collection: number[]): number[][] {
    return RangeReduce.pairFromArray(collection);
  }

  static pairFromArray(array: number[]): number[][] {
    const result: number[][] = [];
    let list: number[] = [];
    for (let index = 0; index < array.length; index++) {
      const current = array[index];
      const previous = index > 0 ? array[index - 1] : -10;
      const next = index + 1 < array.length ? array[index + 1] : -10;
      if (previous + 1 === current && current + 1 === next) {
        continue;
      }
      if (previous + 1 === current) {
        list.push(current);
        list = [];
      } else {
        list = [current];
        result.push(list);
      }
    }
    return result;
  }
}

export type SelectOptions = keyof ReturnType<typeof selectOptions>;

export function selectOptions() {
  return {
    "All weeks": Views.WORK_WEEK,
    "Week interval": Views.WORK_WEEK,
  } as const;
}

/**
 * @param value - the value to be limited
 * @param min - inclusive minimum value
 * @param max - inclusive maximum balue
 * @returns
 * limited value
 */
export function limitedToRange(value: number, min: number, max: number) {
  return Math.max(Math.min(value, max), min);
}

export function getTrackName(track: RegistrationTrack, mapping: Mapping) {
  return mapping.parse("trackNumber", track.teObject);
}

type PresentObject = (object: CatalogTEObject) => string;

function presentLabel(mapping: Mapping): PresentObject {
  return (object: CatalogTEObject) => {
    return mapping.string(
      {
        objectTypeGroup: mapping.getObjectTypeKeyById(
          object.types[0]
        ) as EApplicationObjectTypeGroup,
        appProperty: EAppProperties.LABEL,
      },
      object
    );
  };
}

export function presentCategory(mapping: Mapping) {
  return (dedicated: DedicatedTrack) => {
    if (dedicated.kind === "category") {
      return dedicated.data
        .map(
          (category) => `${mapping.fieldname(category.id)} / ${category.value}`
        )
        .join(", ");
    }
    return "";
  };
}

export function presentRelation(mapping: Mapping) {
  return (dedicated: DedicatedTrack, fetchedObjects: TTEObject[]) => {
    if (dedicated.kind === "relation") {
      const foundRelations = fetchedObjects.reduce<TTEObject[]>(
        (foundRelations, obj) => {
          const found = dedicated.data.some((data) => data.id === obj.id);
          if (found) {
            return [...foundRelations, obj];
          }

          return foundRelations;
        },
        []
      );

      if (foundRelations.length > 0) {
        return foundRelations.map(presentLabel(mapping)).join(", ");
      }
    }
    return "";
  };
}

type CreateTrackNumbersProps = {
  events: CalendarEvent[];
  mapping: Mapping;
  tracks: Registration["tracks"];
};

export function createTrackNumbers({
  events,
  mapping,
  tracks,
}: CreateTrackNumbersProps) {
  const sortedEvents = sortEventsOnTrackNumber({ events, tracks, mapping });
  return sortedEvents.reduce<string[]>((trackNumbers, event) => {
    const track = tracks[event.data.allocationObjectId];
    if (!isDefined(track)) return trackNumbers;

    const trackNumber = getTrackName(track, mapping);
    if (trackNumbers.includes(trackNumber) || trackNumber === "") {
      return trackNumbers;
    }
    return [...trackNumbers, trackNumber];
  }, []);
}

type SortEventsOnTrackNumber = {
  tracks: Registration["tracks"];
  events: CalendarEvent[];
  mapping: Mapping;
  reverse?: boolean;
};

export function sortEventsOnTrackNumber({
  events,
  tracks,
  mapping,
  reverse,
}: SortEventsOnTrackNumber) {
  return events.sort((a, b) => {
    const aTrack = tracks[a.data.allocationObjectId];
    const bTrack = tracks[b.data.allocationObjectId];
    if (!isDefined(aTrack) || !isDefined(bTrack)) return 0;

    const aTrackNumber = mapping.parse("trackNumber", aTrack.teObject);
    const bTrackNumber = mapping.parse("trackNumber", bTrack.teObject);

    return reverse
      ? bTrackNumber.localeCompare(aTrackNumber)
      : aTrackNumber.localeCompare(bTrackNumber);
  });
}

type FindEventColor = {
  index: number;
};
export function getColorIndex({ index }: FindEventColor): number {
  if (index < 0) {
    return -1;
  }
  return index % nbrOfEventColors;
}

type ConflictProps = {
  registration: Registration;
  event: CalendarEvent;
  mapping: Mapping;
  translations: RegistrationRequiredTranslations;
};

export function displayAllConflicts(props: ConflictProps) {
  const { event, registration } = props;

  // Conflicts that can be merged into one notification
  // Each entry in the object will be a different conflict notification
  const mergedConflicts = mergeConflicts({ event, registration });
  const conflicts = populateWeeksOfMergedConflicts({
    mergedConflicts,
    registration,
  });

  return conflicts.map(({ key, reservationIds, weeks }) => {
    return displayConflict(props, {
      key,
      reservationIds: reservationIds ?? [],
      weeks,
    });
  });
}

function displayConflict(
  { registration, mapping, translations }: ConflictProps,
  conflict: { key: string; reservationIds: number[]; weeks: number[] }
) {
  const { reservationIds, weeks } = conflict;
  // All conflict reservations have the same start and end time (day, hour and minute)
  // Also have the same course and activity type because it is the same track
  const event = registration.events.find((e) => e.id == reservationIds[0]);

  if (!isDefined(event)) return null;

  const start = dayjs(event.start.local);
  const end = dayjs(event.end.local);
  const day = start.format("ddd");
  const time = `${start.format("HH:mm")} - ${end.format("HH:mm")}`;
  const weeksText = `${translations.weekAbbreviation} ${RangeReduce.pairPresent(
    weeks
  )}`;

  const objectInfo =
    event.reservation.objects
      ?.map((o) => {
        const course = registration.courses[o.objectId];
        if (isDefined(course))
          return mapping.string("courseLabel", course.teObject);
        const track = registration.tracks[o.objectId];
        if (isDefined(track))
          return mapping.string("activityType", track.teObject);
        return "";
      })
      .join(" ") ?? "";

  const text = ` with ${objectInfo.trim()}, ${day} ${time}, ${weeksText} `;

  return (
    <ConflictNotification
      key={`conf_res_${conflict.key}`}
      ids={conflict.reservationIds}
      useText={true}
      text={text}
      className=""
    />
  );
}

type MergedConflictsProps = {
  event: CalendarEvent;
  registration: Registration;
};
type MergeConflictsRecord = UnsafeRecord<string, number[]>;
/**
 * @description - This function is used to group conflicts with each other and which should be displayed on a different row.
 * @param event - Calendar event to find conflicts with
 * @param registration - Registration object
 * @returns Record with key as trackId and date based on day and time.
 * Value is an array of reservationIds that our track on date and time in key have conflicts with
 * Ex: {[myId-Thu 10:00-12:00]: [reservationId1, reservationId2]}
 */
// eslint-disable-next-line import/no-unused-modules
export function mergeConflicts({
  event,
  registration,
}: MergedConflictsProps): MergeConflictsRecord {
  // Conflicts that can be merged into one notification
  // Each entry in the object will be a different conflict notification
  return event.data.reservations.reduce<UnsafeRecord<string, number[]>>(
    (mergedConflicts, reservation) => {
      const registrationEvent = registration.events.find(
        (e) => e.id == reservation.id
      );

      const allocationConflicts = registrationEvent?.allocationConflicts;

      if (!isDefined(allocationConflicts)) return mergedConflicts;

      for (const conflict of allocationConflicts) {
        const event = registration.events.find(
          (e) => e.id == conflict.reservationId
        );
        if (!isDefined(event)) continue;

        const { start, end } = event;

        // The day, hour and minute (excluding weeks)
        const startDate = dayjs(start.local).format("ddd HH:mm");
        const endDate = dayjs(end.local).format("ddd HH:mm");
        const key = `${conflict.trackId} ${startDate} ${endDate}`;

        if (mergedConflicts[key] === undefined) {
          mergedConflicts[key] = [conflict.reservationId];
        } else {
          mergedConflicts[key]?.push(conflict.reservationId);
        }
      }

      return mergedConflicts;
    },
    {}
  );
}

type PopulateWeeksOfMergedConflictsProps = {
  mergedConflicts: MergeConflictsRecord;
  registration: Registration;
};
/**
 * @description - Add the week number when the conflict happens
 */
// eslint-disable-next-line import/no-unused-modules
export function populateWeeksOfMergedConflicts({
  mergedConflicts,
  registration,
}: PopulateWeeksOfMergedConflictsProps) {
  return Object.entries(mergedConflicts).map(([key, reservationIds]) => {
    const weeks = uniq(
      reservationIds?.reduce<number[]>((weeks, id) => {
        const registrationEvent = registration.events.find((e) => e.id == id);
        if (!isDefined(registrationEvent)) return weeks;
        const start = dayjs(registrationEvent.start.local);
        return [...weeks, start.week()];
      }, [])
    );
    return { key, reservationIds, weeks };
  });
}
