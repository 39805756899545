import "./allocationObjectPage.scss";
import { Button, Title } from "@timeedit/registration-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useCallback, useContext, useMemo } from "react";
import {
  RegistrationTrack,
  RegistrationTrackList,
  isDefined,
} from "@timeedit/registration-shared";
import { Layout } from "antd";
import { SignalBadge } from "../../SignalBadge";
import { EventPropGetter, ViewsProps } from "react-big-calendar";
import cn from "classnames";
import {
  RangeReduce,
  createTrackNumbers,
  getColorIndex,
  getTrackName,
} from "../utils";
import { useIsDesktop } from "../../../utils/hooks";
import { CalendarEvent, createEvents } from "../../../utils/events";
import { AllocationObjectModalContent } from "../Calendar/CalendarModalContent/AllocationObjectModalContent";
import { RegistrationCalendar } from "../Calendar/RegistrationCalendar";
import AllocationObjectListView from "../Calendar/CalendarListView/AllocationObjectListView";
import { RegistrationRequiredTranslations } from "../types";
import {
  DateIntervalContext,
  IdContext,
  RegistrationContext,
  UtilContext,
} from "../RegistrationContexts";
import { uniqBy } from "lodash";

export function AllocationObjectsPage() {
  const { mapping, translations, navigate } = useContext(UtilContext);
  const { allowedDateInterval, dateInterval } = useContext(DateIntervalContext);
  const { registration, mode } = useContext(RegistrationContext);
  const { id, setId, studentId } = useContext(IdContext);

  const views = createViews();

  const isDesktop = useIsDesktop();

  const trackList: RegistrationTrackList | undefined =
    registration.trackLists?.[id];

  const reservations = (registration.events ?? [])
    .filter(isDefined)
    .filter((event) => trackList?.children.includes(event.trackId));

  const course =
    isDefined(trackList?.parentId) &&
    registration.courses[trackList.parentId]?.teObject;
  const courseTitle = mapping.parse("courseTitle", course);
  const courseLabel = mapping.parse("courseLabel", course);

  const title = (
    <div>
      <Title level={3} style={{ margin: 0 }}>
        {trackList?.label ?? ""}
      </Title>
      <div>
        {courseTitle} - {courseLabel}
      </div>
    </div>
  );

  const events = createEvents({
    reservations,
  });

  const eventPropGetter = createEventPropGetter(events);

  return (
    <Layout className="allocationObjectPage">
      <div className="allocationObjectPage__header">
        {!isDesktop && <BackButton handleGoBack={handleGoBack} />}
        {title}
        <div className="allocationObjectPage__status">
          {isDefined(trackList?.allocationSignal) && (
            <SignalBadge
              signal={trackList?.allocationSignal}
              translations={translations}
            />
          )}
        </div>
      </div>
      <div className="allocationObjectPage__content">
        <RegistrationCalendar
          trackListId={id}
          mode={mode}
          translations={translations}
          mapping={mapping}
          registration={registration}
          extraEvents={events}
          views={views}
          title={title}
          eventModalContent={AllocationObjectModalContent}
          eventPropGetter={eventPropGetter}
          allowedDateInterval={allowedDateInterval}
          dateInterval={dateInterval}
        />
      </div>
    </Layout>
  );

  function createEventPropGetter(coloredEvents: CalendarEvent[]) {
    const trackNumbers = createTrackNumbers({
      events: coloredEvents,
      mapping,
      tracks: registration.tracks,
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCallback<EventPropGetter<CalendarEvent>>(
      (event, _start, _end, _isSelected) => {
        const track = registration.tracks[event.data.allocationObjectId];
        if (!isDefined(track)) {
          return {};
        }
        const allocationStatus = track.allocationStatus;

        const createBody = makeCreateBody({
          track,
          event,
          reservedWeeks: event.data.reservedWeeks,
          hasConflict: event.data.hasConflict,
          translations,
        });
        const trackName = getTrackName(track, mapping);

        if (allocationStatus === "ALLOCATED_TO_THIS") {
          const eventOnCurrentAllocObject = events.some(
            (e) => e.id === event.id
          );
          if (eventOnCurrentAllocObject) {
            const trackNumberIndex = trackNumbers.findIndex(
              (trackNumber) => trackNumber === trackName
            );
            const colorIndex =
              trackNumberIndex === -1
                ? -1
                : getColorIndex({ index: trackNumberIndex });

            const titleText = createTitleText(track, trackName);

            event.title = <span className="tracklabel">{titleText}</span>;
            const body = createBody();
            event.body = body;
            event.data.trackNumber = trackNumberIndex;
            if (colorIndex === -1)
              return {
                className: "rbc-event--other",
              };
            event.footer = {
              wantToShowSuccess: true,
              wantToShowConflict: event.data.hasConflict,
            };
            return {
              className: `rbc-event--success`,
            };
          }
          const body = createBody({ showCourses: true });
          event.title = body;
          event.data.trackNumber = -1;

          return {
            className: "rbc-event--other",
          };
        }

        if (allocationStatus === "ALLOCATED_TO_OTHER") {
          const trackNumberIndex = trackNumbers.findIndex(
            (trackNumber) => trackNumber === trackName
          );
          const colorIndex =
            trackNumberIndex === -1
              ? -1
              : getColorIndex({ index: trackNumberIndex });

          const titleText = createTitleText(track, trackName);

          event.title = <span className="tracklabel">{titleText}</span>;
          const body = createBody();
          event.body = body;
          event.data.trackNumber = trackNumberIndex;
          if (colorIndex === -1)
            return {
              className: "rbc-event--other",
            };
          event.footer = {
            wantToShowSuccess: false,
            wantToShowConflict: event.data.hasConflict,
          };
          if (track.open === false) {
            event.footer.text = translations.closed;
            return { className: "rbc-event--closed" };
          }
          if (track.seats.available <= 0) {
            event.footer.text = translations.noSeatsLeft;
            return { className: "rbc-event--no-seats" };
          }
          return { className: `rbc-event--${colorIndex}` };
        }

        if (allocationStatus === "MULTIPLE_ALLOCATIONS") {
          const track = registration.tracks[event.data.allocationObjectId];
          const trackListEventId = isDefined(track)
            ? registration.trackLists[track.parentId]?.id
            : null;
          if (id === trackListEventId) {
            const trackNumberIndex = trackNumbers.findIndex(
              (trackNumber) => trackNumber === trackName
            );
            event.data.trackNumber = trackNumberIndex;
            event.title = <span className="tracklabel">{trackName}</span>;
          } else {
            event.data.trackNumber = -1;
          }

          const body = createBody();
          event.body = body;

          return {
            className: cn(
              "rbc-event--base--error",
              "rbc-event__border",
              "rbc-event__border--red"
            ),
          };
        }

        if (allocationStatus === "NOT_ALLOCATED") {
          const trackNumberIndex = trackNumbers.findIndex(
            (trackNumber) => trackNumber === trackName
          );
          const colorIndex =
            trackNumberIndex === -1
              ? -1
              : getColorIndex({ index: trackNumberIndex });
          event.data.trackNumber = trackNumberIndex;

          if (colorIndex === -1)
            return {
              className: "rbc-event--other",
            };
          const body = createBody();

          const titleText = createTitleText(track, trackName);
          event.title = <span className="tracklabel">{titleText}</span>;
          event.footer = {
            wantToShowSuccess: false,
            wantToShowConflict: event.data.hasConflict,
          };
          event.body = body;
          if (track.open === false) {
            event.footer.text = translations.closed;
            return { className: "rbc-event--no-seats" };
          }
          if (track.seats.available <= 0) {
            event.footer.text = translations.noSeatsLeft;
            return { className: "rbc-event--no-seats" };
          }
          return { className: `rbc-event--${colorIndex}` };
        }

        return {};
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [registration, reservations]
    );
  }

  type MakeCreateTitleProps = {
    translations: RegistrationRequiredTranslations;
    event: CalendarEvent;
    track: RegistrationTrack;
  } & Pick<CalendarEvent["data"], "reservedWeeks" | "hasConflict">;
  function makeCreateBody({
    reservedWeeks,
    translations,
    event,
    track,
  }: MakeCreateTitleProps) {
    return function createBody(props?: { showCourses: boolean }) {
      const weeks = (
        <div>
          {translations.weekAbbreviation}{" "}
          {RangeReduce.pairPresent(reservedWeeks ?? [])}
        </div>
      );
      if (!isDefined(props) || !props.showCourses) {
        return weeks;
      }
      const courseType = mapping.getId("course");
      const courses = Object.values(registration.courses);
      const reservationObjects = uniqBy(
        event.data.reservations
          .flatMap((reservation) => reservation.objects)
          .filter(isDefined),
        "objectId"
      );
      const courseText = reservationObjects
        ?.filter((o) => o.typeId === courseType)
        .map((o) => courses.find((c) => c?.id == o.objectId))
        .filter(isDefined)
        .map((o) => mapping.string("courseLabel", o.teObject));
      return (
        <>
          <div>{courseText}</div>
          <div>{mapping.parse("activityType", track.teObject)}</div>
          {weeks}
        </>
      );
    };
  }

  function createViews(): ViewsProps<CalendarEvent> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(
      () => ({
        work_week: true,
        agenda: AllocationObjectListView,
      }),
      []
    );
  }

  function handleGoBack() {
    setId("");
    navigate(studentId ? "" : "/registration");
  }

  function createTitleText(track: RegistrationTrack, trackName: string) {
    return track.links.length > 0
      ? `${mapping.parse("activityType", track.teObject)} ${trackName}`
      : trackName;
  }
}

interface BackButtonProps {
  handleGoBack: () => void;
}

export function BackButton({ handleGoBack }: BackButtonProps) {
  return (
    <Button
      className="allocationObjectPage__button"
      type="text"
      icon={<ArrowLeftOutlined />}
      onClick={handleGoBack}
    />
  );
}
