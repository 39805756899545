import { ERegion } from "@timeedit/types/lib/enums/region.enum";
import { jwtDecode } from "jwt-decode";
import {
  ITEToken,
  Logger,
  isDefined,
  regionMapping,
} from "@timeedit/registration-shared";
import { EAppEnv } from "@timeedit/types/lib/enums/appEnv.enum";

const regionPlaceHolderInUrl = "|_REGION_|";

export const replaceRegionPlaceholder = (
  urlInput: string,
  region?: ERegion
) => {
  let urlOutput = urlInput;
  if (isDefined(region)) {
    urlOutput = urlInput.replace(regionPlaceHolderInUrl, regionMapping[region]);
  }

  if (urlOutput.includes(regionPlaceHolderInUrl)) {
    throw new Error(
      `The URL '${urlOutput}' still contains the region placeholder: review the inputs to 'replaceRegionPlaceholder'.`
    );
  }

  return urlOutput;
};

export const getRegionFromAccessToken = (
  accessToken: string | null | undefined,
  logger: Logger = console
): ERegion | undefined => {
  if (!accessToken) {
    return;
  }

  try {
    const token = jwtDecode(accessToken);
    const { region } = ITEToken.parse(token);
    return region;
  } catch (error) {
    logger.error(error);
    return;
  }
};

export const getUrlForEnvWithRegionPlaceholder = (
  subdomain: string,
  environment: EAppEnv,
  websocket?: boolean
) => {
  switch (environment) {
    case EAppEnv.BETA:
      return `${
        websocket ? "wss" : "https"
      }://${subdomain}.${regionPlaceHolderInUrl}.timeedit.dev`;
    case EAppEnv.STAGING:
      return `${
        websocket ? "wss" : "https"
      }://${subdomain}.${regionPlaceHolderInUrl}.timeedit.io`;
    case EAppEnv.PRODUCTION:
      return `${
        websocket ? "wss" : "https"
      }://${subdomain}.${regionPlaceHolderInUrl}.timeedit.net`;
    default:
      throw new Error(
        `Can't get regional Url. Not a valid environment: ${environment}`
      );
  }
};

export const getRegionalUrlForEnv = (
  subdomain: string,
  region: ERegion | null | undefined,
  environment: EAppEnv,
  websocket?: boolean
) => {
  if (region && environment !== EAppEnv.DEVELOPMENT) {
    const envBaseUrl = getUrlForEnvWithRegionPlaceholder(
      subdomain,
      environment,
      websocket
    );
    return replaceRegionPlaceholder(envBaseUrl, region);
  }

  if (!isDefined(window)) {
    throw new Error(
      "window is undefined. getRegionalUrlForEnv is not usable in a non-browser environment"
    );
  }
  const origin = window.location.origin;
  return websocket ? origin.replace("http", "ws") : origin;
};
