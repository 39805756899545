import { useMemo } from "react";
import { Button } from "../Button";
import {
  getErrorMessage,
  hasData,
  isErrorWithLogId,
  regionMapping,
} from "@timeedit/registration-shared";
import { Link } from "../Typography/Link";
import { Title } from "../Typography/Title";
import { Text } from "../Typography/Text";
import "./errorInfo.scss";
import { CloseOutlined } from "@ant-design/icons";
import { ERegion } from "@timeedit/types/lib/enums/region.enum";

interface Translations {
  retry: string;
}

interface ErrorInfoProps {
  translations: Translations;
  error: unknown;
  recover: () => void;
  region: ERegion;
}

export function ErrorInfo({
  translations,
  error,
  recover,
  region,
}: ErrorInfoProps) {
  const message = getErrorMessage(error);
  const newLineText = message.split("\n").map((str, index) => (
    <Text type="secondary" key={index}>
      {str}
    </Text>
  ));

  const gcpLogLink: JSX.Element = useMemo(() => {
    if (
      isErrorWithLogId(error) &&
      import.meta.env.VITE_TE_APP_ENV === "production"
    ) {
      if (Array.isArray(error.logId)) {
        return (
          <div className="error-info__gcp-error">
            <Text>Log ids: </Text>
            {error.logId.map((logId) => (
              <Text key={logId}>{logId}</Text>
            ))}
          </div>
        );
      }
      return (
        <Text className="error-info__gcp-error">Log Id: {error.logId}</Text>
      );
    }
    return <LinkToLog error={error} region={region} />;
  }, [error, region]);

  return (
    <div className="error-info__container">
      <CloseOutlined className="error-info__icon" />
      <Title level={3}>Error in Registration</Title>
      {newLineText}
      <br />
      {gcpLogLink}
      <Button type="primary" onClick={recover}>
        {translations.retry}
      </Button>
    </div>
  );
}

type LinkToLogProps = {
  error: unknown;
  region: ERegion;
};

function LinkToLog({ error: _error, region }: LinkToLogProps) {
  const error = hasData(_error) ? _error.data : _error;

  if (!isErrorWithLogId(error)) {
    return null;
  }
  if (Array.isArray(error.logId)) {
    return (
      <div className="error-info__gcp-error">
        {error.logId.map((logId) => {
          const url = `https://console.cloud.google.com/logs/query;query=logName:%22winston_log%22%0AjsonPayload.metadata.logId:%22${logId}%22?project=te-${regionMapping[
            region
          ].toLowerCase()}-consume`;
          return (
            <Link href={url} key={logId}>
              {url}
            </Link>
          );
        })}
      </div>
    );
  }
  const url = `https://console.cloud.google.com/logs/query;query=logName:%22winston_log%22%0AjsonPayload.metadata.logId:%22${
    error.logId
  }%22?project=te-${regionMapping[region].toLowerCase()}-consume`;
  return (
    <Link className="error-info__gcp-error" href={url}>
      {url}
    </Link>
  );
}
