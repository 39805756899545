import {
  RegistrationMain,
  Spin,
  OnAllocate,
  OnDeallocate,
} from "@timeedit/registration-components";
import { useAppSelector, useMapping } from "../../redux/hooks";
import {
  useSwapMutation,
  useDeallocateMutation,
  useFetchRegistrationQuery,
} from "../../redux/api/registration";
import { useMutationSubscriptionRef, useNavigate } from "../../utils/hooks";
import {
  RegistrationError,
  getErrorMessage,
  getStatusCode,
  isDefined,
} from "@timeedit/registration-shared";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getKnownErrorTexts } from "../../utils/errors";

export function RegistrationPage() {
  const mapping = useMapping();
  const { allowedDateInterval, dateInterval } = useAppSelector(
    (state) => state.schedule
  );
  const signal = useAppSelector((state) => state.computed.signal);
  const navigate = useNavigate();
  const params = useParams();

  const allocateRef = useMutationSubscriptionRef();
  const [swap, swapResult] = useSwapMutation();

  const deallocateRef = useMutationSubscriptionRef();
  const [deallocate, deallocateResult] = useDeallocateMutation();

  const registration = useFetchRegistrationQuery();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalTexts, setErrorModalTexts] = useState({
    text: "",
    title: "",
  });

  useEffect(() => {
    if (swapResult.isSuccess) {
      const hasErrors = Object.values(swapResult.data.failedGroups).some(
        (group) =>
          group?.some(
            (error) =>
              isDefined(error.reasons?.failsWithMessage) ||
              isDefined(error.reasons?.failsWithTracks)
          )
      );

      if (hasErrors) {
        setErrorModalTexts(() =>
          getKnownErrorTexts({
            kind: "failedGroups",
            failedGroups: swapResult.data.failedGroups,
          })
        );

        setOpenErrorModal(true);
      }
    }
    if (swapResult.isError || deallocateResult.isError) {
      const errorMessage = getErrorMessage(
        swapResult.error ?? deallocateResult.error
      );
      const errorCode = getStatusCode(
        swapResult.error ?? deallocateResult.error
      );

      setErrorModalTexts(() =>
        getKnownErrorTexts({
          kind: "message",
          errorMessage,
          errorCode,
        })
      );

      setOpenErrorModal(true);
    }
  }, [
    swapResult.data?.failedGroups,
    swapResult.isSuccess,
    swapResult.isError,
    deallocateResult.isError,
    deallocateResult.error,
    swapResult.error,
  ]);

  if (registration.isError) {
    throw new RegistrationError({
      errors: [registration.error],
    });
  }

  if (registration.isLoading || !registration.data) {
    return (
      <>
        <div
          style={{
            marginTop: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ display: "static" }}>
            <Spin contained />
            <span style={{ display: "block", marginTop: "16px" }}>
              Loading your modules... This might take some time, please do not
              reload the page
            </span>
          </span>
        </div>
      </>
    );
  }

  const isLoading =
    registration.isLoading ||
    registration.isFetching ||
    swapResult.isLoading ||
    deallocateResult.isLoading;

  return (
    <>
      <RegistrationMain
        params={params}
        signal={signal}
        allocationControlActions={{
          onAllocate: handleAllocate,
          onDeallocate: handleDeallocate,
          isLoading,
        }}
        navigate={navigate}
        mappingData={mapping.store.mappingData}
        registration={registration.data}
        allowedDateInterval={allowedDateInterval}
        dateInterval={dateInterval}
      />
      <Modal
        open={openErrorModal}
        title={errorModalTexts.title}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
        onOk={() => {
          setOpenErrorModal(false);
        }}
      >
        <p>{errorModalTexts.text}</p>
      </Modal>
    </>
  );

  function handleAllocate({ newObjectId, prevObjectIds }: OnAllocate) {
    if (!isDefined(registration.data)) return;
    allocateRef.current = swap({
      studentId: registration.data.studentId,
      newObjectId,
      prevObjectIds,
      conflictControl: "none",
    });
  }

  function handleDeallocate({ objectIds }: OnDeallocate) {
    if (!isDefined(registration.data)) return;
    const studentInfo = { [registration.data.studentId]: objectIds };
    deallocateRef.current = deallocate({ studentInfo });
  }
}
