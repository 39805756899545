import {
  getErrorMessage,
  isDefined,
  RegistrationError,
  translations,
} from "@timeedit/registration-shared";
import { useRouteError } from "react-router-dom";
import { useContext } from "react";
import { ErrorInfo } from "@timeedit/registration-components";
import { useAppSelector, useUserData } from "../redux/hooks";
import { AuthContext } from "@timeedit/ui-components";
import { urls } from "./RootPage/AuthRoot";
import { ERegion } from "@timeedit/types/lib/enums/region.enum";

export function ErrorPage() {
  const error = useRouteError();
  const user = useUserData();

  const { loginData } = useAppSelector((state) => state.login);
  const logout = useContext(AuthContext)?.logout;
  const message = getErrorMessage(error);
  const unAuthorized = message.includes("Unauthorized access");

  const recover = createRecoverFunction(error);

  return (
    <ErrorInfo
      error={error}
      recover={recover}
      translations={translations}
      region={user.data?.region ?? ERegion.EU_EES}
    />
  );

  function createRecoverFunction(error: unknown) {
    if (unAuthorized) {
      return unAuthorizedRetry;
    }

    // Default recover is to refresh the page
    return error instanceof RegistrationError
      ? () => error.recover()
      : () => location.reload();
  }

  async function unAuthorizedRetry() {
    if (isDefined(logout)) {
      await logout();
    }
    window.location.replace(
      `${urls.AUTH_URL}/organizations/${loginData?.organizationId}`
    );
  }
}
