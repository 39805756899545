import { EFieldType, ETokenType } from "@timeedit/types/lib/enums";
import { ERegion } from "@timeedit/types/lib/enums/region.enum";
import {
  Catalog,
  ITEToken,
  TType,
  Registration,
  TField,
  TReservation,
  TTEFieldValue,
  TTEObject,
  TimeZoneObject,
} from "../../../zod";

/**
 * string singulars and tens (ex "1"): settings
 * number singularsa and tens (ex 1): objects
 * number hundreds (ex 101): fields
 * number twohundreds (ex 202): types
 * number threehundreds (ex 301): reservations
 *
 * ***Types***
 * 201: course
 * 202: allocationGroup
 * 203: allocationObject
 * 220: student
 */

export const mockDateTime = {
  currentDateTime: {
    local: "2023-08-24 00:00:00",
    timezone: "Europe/Stockholm",
    unix: 1692879672,
  },
  offsetDateTime: {
    local: "2023-08-24 00:00:00",
    timezone: "Europe/Stockholm",
    unix: 1692879672,
  },
};

export const mockTimeZones = [
  {
    id: 1,
    extId: "CET",
    name: "Central European Time",
    timeZone: "CET",
    identifiers: ["Europe/Stockholm"],
    isDefault: true,
  },
  {
    id: 2,
    extId: "NZT",
    name: "New Zealand Time",
    timeZone: "NZST",
    identifiers: ["Pacific/Auckland"],
    isDefault: false,
  },
] satisfies TimeZoneObject[];

export const commonFieldProps = {
  refSeparator: " ",
  searchable: false,
  defaultValue: "",
  checkSum: 0,
  categories: [],
  referenceFields: [],
  types: [],
} satisfies Partial<TField>;

export const mockTeFields: TField[] = [
  {
    ...commonFieldProps,
    id: 101,
    extId: "ext1",
    name: "field1",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 102,
    extId: "ext2",
    name: "field2",
    fieldType: EFieldType.SIGNATURE,
  },
  {
    ...commonFieldProps,
    id: 103,
    extId: "ext3",
    name: "field3",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 104,
    extId: "ext4",
    name: "field4",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 105,
    extId: "ext5",
    name: "field5",
    fieldType: EFieldType.CATEGORY,
  },
  {
    ...commonFieldProps,
    id: 106,
    extId: "ext6",
    name: "field6",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 107,
    extId: "ext7",
    name: "field7",
    fieldType: EFieldType.LENGTH,
  },
  {
    ...commonFieldProps,
    id: 108,
    extId: "ext8",
    name: "field8",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 109,
    extId: "ext9",
    name: "field9",
    fieldType: EFieldType.INTEGER,
  },
  {
    ...commonFieldProps,
    id: 110,
    extId: "ext10",
    name: "field10",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 111,
    extId: "ext11",
    name: "field11",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 112,
    extId: "ext12",
    name: "field12",
    fieldType: EFieldType.CATEGORY,
  },
  {
    ...commonFieldProps,
    id: 113,
    extId: "ext13",
    name: "field13",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 114,
    extId: "ext14",
    name: "field14",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 115,
    extId: "ext15",
    name: "field15",
    fieldType: EFieldType.CATEGORY,
  },
  {
    ...commonFieldProps,
    id: 116,
    extId: "ext16",
    name: "field16",
    fieldType: EFieldType.TEXT,
  },
  {
    ...commonFieldProps,
    id: 117,
    extId: "ext17",
    name: "field17",
    fieldType: EFieldType.TEXT,
  },
];

export const mockStudentRelationIds = [1, 2, 3];
export const mockStudentFields: TTEFieldValue[] = [
  { fieldId: 201, values: ["Masters"] },
  { fieldId: 202, values: ["Swedish"] },
];

export const mockAuthorization = "Bearer token";

export const mockCustomerSignature = "university";

export const mockTeObjects: TTEObject[] = [
  {
    id: 1,
    extId: "MATH-101",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [
      {
        fieldId: 101,
        values: ["Math 101"],
      },
      {
        fieldId: 102,
        values: ["MATH-101"],
      },
      {
        fieldId: 103,
        values: ["Fall 2023"],
      },
    ],
    relations: [
      {
        start: 0,
        end: 0,
        objectIds: [4, 5, 6, 7, 8, 9],
      },
    ],
    members: [],
    types: [201],
  },
  {
    id: 4,
    extId: "OBJECT-1",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [
      {
        fieldId: 106,
        values: ["Object 1"],
      },
      {
        fieldId: 107,
        values: ["200000"],
      },
      {
        fieldId: 109,
        values: ["5"],
      },
      {
        fieldId: 110,
        values: [],
      },
      {
        fieldId: 111,
        values: ["GROUP-A"],
      },
      {
        fieldId: 112,
        values: ["SELECT"],
      },
      {
        fieldId: 113,
        values: ["17/07/2023"],
      },
      {
        fieldId: 114,
        values: ["12/11/2023"],
      },
      {
        fieldId: 115,
        values: ["DURING_INTERVAL"],
      },
    ],
    relations: [
      {
        start: 0,
        end: 0,
        objectIds: [10],
      },
      {
        start: 0,
        end: 0,
        objectIds: [6],
      },
      {
        start: 0,
        end: 0,
        objectIds: [1],
      },
    ],
    members: [{ start: 0, end: 0, objectId: 12 }],
    types: [203],
  },
  {
    id: 5,
    extId: "OBJECT-2",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [
      {
        fieldId: 106,
        values: ["Object 2"],
      },
      {
        fieldId: 107,
        values: ["300000"],
      },
      {
        fieldId: 109,
        values: ["5"],
      },
      {
        fieldId: 110,
        values: ["89"],
      },
      {
        fieldId: 111,
        values: ["GROUP-B"],
      },
      {
        fieldId: 112,
        values: ["SELECT"],
      },
      {
        fieldId: 113,
        values: ["17/07/2023"],
      },
      {
        fieldId: 114,
        values: ["12/11/2023"],
      },
      {
        fieldId: 115,
        values: [""],
      },
      {
        fieldId: 116,
        values: ["50%"],
      },
    ],
    relations: [
      {
        start: 0,
        end: 0,
        objectIds: [11],
      },
      {
        start: 0,
        end: 0,
        objectIds: [1],
      },
    ],
    members: [
      { start: 0, end: 0, objectId: 12 },
      { start: 0, end: 0, objectId: 13 },
    ],
    types: [203],
  },
  {
    id: 6,
    extId: "OBJECT-3",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [
      {
        fieldId: 106,
        values: ["Object 3"],
      },
      {
        fieldId: 107,
        values: ["400000"],
      },
      {
        fieldId: 109,
        values: ["5"],
      },
      {
        fieldId: 110,
        values: ["12", "89"],
      },
      {
        fieldId: 111,
        values: ["GROUP-B"],
      },
      {
        fieldId: 112,
        values: ["SELECT"],
      },
      {
        fieldId: 113,
        values: ["17/07/2023"],
      },
      {
        fieldId: 114,
        values: ["12/11/2023"],
      },
      {
        fieldId: 115,
        values: [""],
      },
    ],
    relations: [
      {
        start: 0,
        end: 0,
        objectIds: [4],
      },
      {
        start: 0,
        end: 0,
        objectIds: [1],
      },
    ],
    types: [203],
    members: [],
  },
  {
    id: 7,
    extId: "OBJECT-4",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [
      {
        fieldId: 106,
        values: ["Object 4"],
      },
      {
        fieldId: 107,
        values: ["500000"],
      },
      {
        fieldId: 109,
        values: ["5"],
      },
      {
        fieldId: 110,
        values: [],
      },
      {
        fieldId: 111,
        values: ["GROUP-C"],
      },
      {
        fieldId: 112,
        values: ["SELECT"],
      },
      {
        fieldId: 113,
        values: ["17/07/2023"],
      },
      {
        fieldId: 114,
        values: ["12/11/2023"],
      },
      {
        fieldId: 115,
        values: [""],
      },
    ],
    relations: [
      {
        start: 0,
        end: 0,
        objectIds: [1],
      },
    ],
    types: [203],
    members: [],
  },
  {
    id: 8,
    extId: "OBJECT-5",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [
      {
        fieldId: 106,
        values: ["Object 5"],
      },
      {
        fieldId: 107,
        values: ["500000"],
      },
      {
        fieldId: 109,
        values: ["5"],
      },
      {
        fieldId: 110,
        values: [],
      },
      {
        fieldId: 111,
        values: ["GROUP-C"],
      },
      {
        fieldId: 112,
        values: ["SELECT"],
      },
      {
        fieldId: 113,
        values: ["17/07/2023"],
      },
      {
        fieldId: 114,
        values: ["12/11/2023"],
      },
      {
        fieldId: 115,
        values: [""],
      },
    ],
    relations: [
      {
        start: 0,
        end: 0,
        objectIds: [1],
      },
    ],
    types: [203],
    members: [],
  },
  {
    id: 9,
    extId: "OBJECT-6",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [
      {
        fieldId: 106,
        values: ["Object 6"],
      },
      {
        fieldId: 107,
        values: ["600000"],
      },
      {
        fieldId: 109,
        values: ["6"],
      },
      {
        fieldId: 110,
        values: [],
      },
      {
        fieldId: 111,
        values: ["GROUP-A"],
      },
      {
        fieldId: 112,
        values: ["SELECT"],
      },
      {
        fieldId: 113,
        values: ["17/07/2023"],
      },
      {
        fieldId: 114,
        values: ["12/11/2023"],
      },
      {
        fieldId: 115,
        values: ["DURING_INTERVAL"],
      },
    ],
    relations: [
      {
        start: 0,
        end: 0,
        objectIds: [1],
      },
    ],
    members: [{ start: 0, end: 0, objectId: 13 }],
    types: [203],
  },
  {
    id: 12,
    extId: "STUDENT-1",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [],
    relations: [{ start: 0, end: 0, objectIds: [1] }],
    types: [220],
    members: [],
  },
  {
    id: 13,
    extId: "STUDENT-2",
    active: true,
    created: 1723782775,
    abstract: false,
    virtual: false,
    fields: [],
    relations: [{ start: 0, end: 0, objectIds: [1] }],
    types: [220],
    members: [],
  },
];

export const mockTeReservationWithTwoTracks = [
  {
    id: 201,
    extId: "RESERVATION-MANY_TRACKS",
    begin: 1690444800,
    end: 1690448400,
    objects: [
      { typeId: 203, objectId: 4 },
      { typeId: 203, objectId: 5 },
    ],
  },
];

export const mockTeReservations = [
  {
    id: 301,
    extId: "RESERVATION-1",
    begin: 1690444800,
    end: 1690448400,
    objects: [{ typeId: 203, objectId: 4 }],
  },
  {
    id: 302,
    extId: "RESERVATION-2",
    begin: 1690446600,
    end: 1690450200,
    objects: [{ typeId: 203, objectId: 5 }],
  },
  {
    id: 303,
    extId: "RESERVATION-3",
    begin: 1690441800,
    end: 1690450200,
    objects: [{ typeId: 203, objectId: 6 }],
  },
  {
    id: 304,
    extId: "RESERVATION-4",
    begin: 1690441800,
    end: 1690445400,
    objects: [{ typeId: 203, objectId: 7 }],
  },
  {
    id: 305,
    extId: "RESERVATION-5",
    begin: 1690450200,
    end: 1690666200,
    objects: [{ typeId: 203, objectId: 6 }],
  },
] satisfies TReservation[];

export const mockUser = {
  region: ERegion.EU_EES,
  id: "64a7d55ed7f76100238fa0e9",
  type: ETokenType.USER,
  username: "consume.test@timeedit.com",
  email: "consume.test@timeedit.com",
  organizationId: "6405a438ddd66a00221435f7",
  appPermissions: [],
  scopes: ["TE_REGISTRATION::user"],
  organizationsAndRoles: [],
  iat: 1691662122,
  exp: 1723219722,
} satisfies ITEToken;

export const mockCatalogPreAllocationGroup: Catalog = {
  courses: {
    1: {
      id: 1,
      extId: "MATH-101",
      typeId: 201,
      label: "Math 101",
      teObject: mockTeObjects.find((o) => o.id === 1)!,
      children: [4, 5, 6, 7],
    },
  },
  trackLists: {},
  tracks: {
    4: {
      id: 4,
      extId: "OBJECT-1",
      typeId: 203,
      label: "Object 1",
      teObject: mockTeObjects.find((o) => o.id === 4)!,
      parentId: 1,
      links: [6],
    },
    5: {
      id: 5,
      extId: "OBJECT-2",
      typeId: 203,
      label: "Object 2",
      teObject: mockTeObjects.find((o) => o.id === 5)!,
      parentId: 1,
      links: [],
    },
    6: {
      id: 6,
      extId: "OBJECT-3",
      typeId: 203,
      label: "Object 3",
      teObject: mockTeObjects.find((o) => o.id === 6)!,
      parentId: 1,
      links: [4],
    },
    7: {
      id: 7,
      extId: "OBJECT-4",
      typeId: 203,
      label: "Object 4",
      teObject: mockTeObjects.find((o) => o.id === 7)!,
      parentId: 1,
      links: [],
    },
    8: {
      id: 8,
      extId: "OBJECT-5",
      typeId: 203,
      label: "Object 5",
      teObject: mockTeObjects.find((o) => o.id === 8)!,
      parentId: 1,
      links: [],
    },
  },
};

export const mockCatalog: Catalog = {
  courses: {
    1: {
      id: 1,
      extId: "MATH-101",
      typeId: 201,
      label: "Math 101",
      children: [1460478604, 1460478605, 1460478606],
      teObject: mockTeObjects.find((o) => o.id === 1)!,
    },
  },
  trackLists: {
    1460478604: {
      id: 1460478604,
      label: "GROUP-A",
      parentId: 1,
      children: [4, 6],
    },
    1460478605: {
      id: 1460478605,
      label: "GROUP-B",
      parentId: 1,
      children: [5],
    },
    1460478606: {
      id: 1460478606,
      label: "GROUP-C",
      parentId: 1,
      children: [7, 8],
    },
  },
  tracks: {
    4: {
      id: 4,
      extId: "OBJECT-1",
      typeId: 203,
      label: "Object 1",
      teObject: mockTeObjects.find((o) => o.id === 4)!,
      parentId: 1460478604,
      links: [6],
    },
    5: {
      id: 5,
      extId: "OBJECT-2",
      typeId: 203,
      label: "Object 2",
      teObject: mockTeObjects.find((o) => o.id === 5)!,
      parentId: 1460478605,
      links: [],
    },
    6: {
      id: 6,
      extId: "OBJECT-3",
      typeId: 203,
      label: "Object 3",
      teObject: mockTeObjects.find((o) => o.id === 6)!,
      parentId: 1460478604,
      links: [4],
    },
    7: {
      id: 7,
      extId: "OBJECT-4",
      typeId: 203,
      label: "Object 4",
      teObject: mockTeObjects.find((o) => o.id === 7)!,
      parentId: 1460478606,
      links: [],
    },
    8: {
      id: 8,
      extId: "OBJECT-5",
      typeId: 203,
      label: "Object 5",
      teObject: mockTeObjects.find((o) => o.id === 8)!,
      parentId: 1460478606,
      links: [],
    },
  },
};

export const mockRegistration: Registration = {
  studentId: 12,
  timezone: "Europe/Stockholm",
  events: [],
  conflicts: [],
  courses: {
    1: {
      ...mockCatalog.courses[1]!,
      id: 1,
      children: [1460478604, 1460478605, 1460478606],
      open: true,
      showInRegistration: true,
    },
  },
  trackLists: {
    1460478604: {
      ...mockCatalog.trackLists[1460478604]!,
      id: 1460478604,
      children: [4, 6],
      parentId: 1,
      state: "SELECT",
      allocationSignal: "OK",
      open: true,
      showInRegistration: true,
    },
    1460478605: {
      ...mockCatalog.trackLists[1460478605]!,
      id: 1460478605,
      children: [5],
      parentId: 1,
      state: "SELECT",
      allocationSignal: "OK",
      open: true,
      showInRegistration: true,
    },
    1460478606: {
      ...mockCatalog.trackLists[1460478606]!,
      id: 1460478606,
      children: [7, 8],
      parentId: 1,
      state: "SELECT",
      allocationSignal: "ISSUE",
      open: true,
      showInRegistration: true,
    },
  },
  tracks: {
    4: {
      ...mockCatalog.tracks[4]!,
      id: 4,
      parentId: 1460478604,
      seats: {
        taken: 1,
        available: 4,
        total: 5,
        raw: { taken: 1, available: 4, total: 5, buffer: 0 },
      },
      allocationStatus: "ALLOCATED_TO_THIS",
      open: true,
      showInRegistration: true,
      dedicated: {
        kind: "none",
        data: undefined,
      },
      hidden: false,
      links: [6],
    },
    5: {
      ...mockCatalog.tracks[5]!,
      id: 5,
      parentId: 1460478605,
      seats: {
        taken: 0,
        available: 5,
        total: 5,
        raw: { taken: 0, available: 5, total: 5, buffer: 0 },
      },
      allocationStatus: "NOT_ALLOCATED",
      open: true,
      showInRegistration: true,
      dedicated: {
        kind: "none",
        data: undefined,
      },
      hidden: false,
      links: [],
    },
    6: {
      ...mockCatalog.tracks[6]!,
      id: 6,
      parentId: 1460478604,
      seats: {
        taken: 0,
        available: 5,
        total: 5,
        raw: { taken: 0, available: 5, total: 5, buffer: 0 },
      },
      allocationStatus: "NOT_ALLOCATED",
      open: true,
      showInRegistration: true,
      dedicated: {
        kind: "none",
        data: undefined,
      },
      hidden: false,
      links: [4],
    },
    7: {
      ...mockCatalog.tracks[7]!,
      id: 7,
      parentId: 1460478606,
      seats: {
        taken: 0,
        available: 5,
        total: 5,
        raw: { taken: 0, available: 5, total: 5, buffer: 0 },
      },
      allocationStatus: "NOT_ALLOCATED",
      open: true,
      showInRegistration: true,
      dedicated: {
        kind: "none",
        data: undefined,
      },
      hidden: false,
      links: [],
    },
    8: {
      ...mockCatalog.tracks[8]!,
      id: 8,
      parentId: 1460478606,
      seats: {
        taken: 0,
        available: 5,
        total: 5,
        raw: { taken: 0, available: 5, total: 5, buffer: 0 },
      },
      allocationStatus: "NOT_ALLOCATED",
      open: true,
      showInRegistration: true,
      dedicated: {
        kind: "none",
        data: undefined,
      },
      hidden: false,
      links: [],
    },
  },
};

export const mockTeType: TType = {
  id: 5,
  extId: "course",
  name: "Course",
  active: true,
};

export const mockTeReservation: TReservation = {
  id: 301,
  extId: "RESERVATION-1",
  begin: 1690444800,
  end: 1690448400,
  objects: [{ typeId: 203, objectId: 4 }],
};
