import { z } from "zod";

export const OptimizerMeta = z.object({
  userId: z.string(),
  timestamp: z.number(),
});
export type OptimizerMeta = z.infer<typeof OptimizerMeta>;

export const OptimizerOutputSchema = z.object({
  outputPath: z.string(),
  jobId: z.string(),
  internalJobId: z.string(),
  jobType: z.string(),
  status: z.string(),
});
export type OptimizerOutputSchema = z.infer<typeof OptimizerOutputSchema>;

export const OptimizerResult = z.object({
  build: z.string(),
  dataVersion: z.number(),
  exit_status: z.string(),
  id: z.string(),
  meta: OptimizerMeta,
  result: z.unknown(),
});
export type OptimizerResult = z.infer<typeof OptimizerResult>;
