import { isDefined } from "../typechecks";
import { TTEObject } from "../zod";

export function toArray(objects?: TTEObject | TTEObject[]) {
  if (!isDefined(objects)) return [];
  return Array.isArray(objects) ? objects : [objects];
}

export function arrayHasValues<E>(array?: E[]): boolean {
  if (!isDefined(array)) return false;
  if (array.length == 0) return false;
  return true;
}
